const ClubProductsInfo = {
  es: [
    {
      title: "Curly Method",
      summary:
        "2 pasos para definir, hidratar, nutrir tus rizos y decir adiós al frizz definitivamente.",
      id: 53055,
      sendingClub: 53309,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/curly-method-club-new.jpg",
    },
    {
      title: "The Absolute",
      summary:
        "La crema antiedad con una innovadora tecnología natural que controla los procesos del envejecimiento para regenerar y rejuvenecer la piel.",
      id: 53123,
      sendingClub: 53275,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-absolute-club-new.jpg",
    },
    {
      title: "Body Power",
      summary:
        "Sinergia de dos productos anticelulíticos y reductores para sacarle el mayor provecho a tus sesiones de ejercicio.",
      id: 53299,
      sendingClub: 53344,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/body-power-club-new.jpg",
    },
    {
      title: "The Real C 30 days",
      summary:
        "El escudo antienvejecimiento más poderoso con Vitamina C 100 % pura. Trabaja la flacidez, manchas, arrugas y la piel apagada. Envase EcoRecarga.",
      id: 152,
      sendingClub: 160,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749_1_2_1.jpg?v=1687427038",
    },

    {
      title: "Boombastic",
      summary:
        "Mascarilla ultranutritiva para una melena de escándalo en 30 minutos.",
      id: 53183,
      sendingClub: 53225,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/boombastic-club-new.jpg",
    },
    {
      title: "Wondermask",
      summary:
        "Mascarilla purificante 5 en 1 para una piel perfecta en 10 minutos. Detoxifica, equilibra la piel y actúa contra los poros abiertos.",
      id: 53102,
      sendingClub: 53226,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/wondermask-club-new.jpg",
    },
    {
      title: "The Cure",
      summary:
        "Serum definitivo con 21 aceites únicos 100% naturales y Bakuchiol para reparar y tratar las arrugas.",
      id: 53252,
      sendingClub: 53252,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-cure-club-new.jpg",
    },
    {
      title: "The Extraordinary Oil",
      summary:
        "Aceite corporal 3 en 1 con Aceites de Almendras, Rosa Mosqueta y Argán que deja tu piel hidratada, radiante y perfecta.",
      id: 52074,
      sendingClub: 53227,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/extraordinary-oil-club-new.jpg",
    },
    {
      title: "Savior",
      summary:
        "Innovador tratamiento capilar que restaura y repara la fibra capilar consiguiendo recuperar la salud y fortaleza de tu cabello.",
      id: 53239,
      sendingClub: 53274,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/savior-club-new.jpg",
    },
    {
      title: "All in One",
      summary:
        "Paleta de maquillaje perfecta para resaltar, sombrear y definir el rostro y la mirada.",
      id: 53228,
      sendingClub: 53316,
      options: [
        /*{
          id: 53229,
          sendingClub: 53310,
          text: "Light",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
        },*/
        {
          id: 53230,
          sendingClub: 53311,
          text: "Medium",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
        },
        {
          id: 53231,
          sendingClub: 53312,
          text: "Tan",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
        },
      ],
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/AllInOne-53228 2.jpg",
    },

    {
      title: "The glow",
      summary:
        "Crema corporal nutritiva con partículas minerales de efecto brillo para una piel firme, tersa, lisa y elástica",
      id: 53176,
      sendingClub: 53278,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-glow-club-new.jpg",
    },
    {
      title: "Extra defined curls",
      summary:
        "3 pasos para definir, nutrir y fijar el cabello rizado sin dañar ni apelmazar el rizo. ",
      id: 53110,
      sendingClub: 53348,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/rizos-definicion-extra-club-ne.jpg",
    },
    {
      title: "The Lift",
      summary:
        "Sérum de ojos que tensa la piel al instante, reduce las arrugas y combate la fatiga con resultados clínicamente probados que duran más de 8 horas.",
      id: 276,
      sendingClub: 276,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/222TheLift-1.jpg?v=1721640569",
    },
    {
      title: "The Architect",
      summary:
        "Tratamiento de ojos con Ácido Hialurónico y Bakuchiol que reduce arrugas, bolsas y ojeras mientras eleva los párpados.",
      id: 220,
      sendingClub: 220,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/53193TARCH_1.jpg?v=1717066642",
    },
  ],
  en: [
    {
      title: "Curly Method",
      summary:
        "2 steps to define, moisturize, nourish your curls and say goodbye to frizz for good.",
      id: 53055,
      sendingClub: 53309,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/curly-method-club-new.jpg",
    },
    {
      title: "The Absolute",
      summary:
        "The anti-aging cream with innovative natural technology that controls aging processes to regenerate and rejuvenate the skin.",
      id: 53123,
      sendingClub: 53275,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-absolute-club-new.jpg",
    },
    {
      title: "Body Power",
      summary:
        "Synergy of two anti-cellulite and slimming products to get the most out of your exercise sessions.",
      id: 53299,
      sendingClub: 53344,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/body-power-club-new.jpg",
    },
    {
      title: "The Real C 30 days",
      summary:
        "The most powerful anti-aging shield with 100% pure Vitamin C. Works on laxity, dark spots, wrinkles and dull skin. EcoRecharge format",
      id: 152,
      sendingClub: 160,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749_1_2_1.jpg?v=1687427038",
    },

    {
      title: "Boombastic",
      summary: "Ultra-nourishing mask for a stunning mane in 30 minutes.",
      id: 53183,
      sendingClub: 53225,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/boombastic-club-new.jpg",
    },
    {
      title: "Wondermask",
      summary:
        "5-in-1 purifying mask for perfect skin in 10 minutes. Detoxifies, balances the skin and acts against open pores.",
      id: 53102,
      sendingClub: 53226,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/wondermask-club-new.jpg",
    },
    {
      title: "The Cure",
      summary:
        "Ultimate serum with 21 unique 100% natural oils and Bakuchiol to repair and treat wrinkles.",
      id: 53252,
      sendingClub: 53252,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-cure-club-new.jpg",
    },
    {
      title: "The Extraordinary Oil",
      summary:
        "3 in 1 body oil with Almond, Rosehip and Argan Oils that leaves your skin hydrated, radiant and perfect.",
      id: 52074,
      sendingClub: 53227,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/extraordinary-oil-club-new.jpg",
    },
    {
      title: "Savior",
      summary:
        "Innovative hair treatment that restores and repairs the hair fiber to recover the health and strength of your hair.",
      id: 53239,
      sendingClub: 53274,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/savior-club-new.jpg",
    },
    {
      title: "All in One",
      summary:
        "Perfect makeup palette for highlighting, shading and defining the face and eyes.",
      id: 53228,
      sendingClub: 53316,
      options: [
        {
          id: 53229,
          sendingClub: 53310,
          text: "Light",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
        },
        {
          id: 53230,
          sendingClub: 53311,
          text: "Medium",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
        },
        {
          id: 53231,
          sendingClub: 53312,
          text: "Tan",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
        },
      ],
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/AllInOne-53228 2.jpg",
    },
    {
      title: "The glow",
      summary:
        "Nourishing body cream with shimmering mineral particles for firm, smooth, supple and elastic skin",
      id: 53176,
      sendingClub: 53278,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-glow-club-new.jpg",
    },
    {
      title: "Extra defined curls",
      summary:
        "3 steps to define, nourish and fix curly hair without damaging or weighing down the curl.",
      id: 53110,
      sendingClub: 53348,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/rizos-definicion-extra-club-ne.jpg",
    },
    {
      title: "The Lift",
      summary:
        "Eye serum that instantly tightens skin, reduces wrinkles, and fights fatigue with clinically proven results lasting 8+ hours.",
      id: 276,
      sendingClub: 276,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/222TheLift-1.jpg?v=1721640569",
    },
    {
      title: "The Architect",
      summary:
        "Eye treatment with Hyaluronic Acid and Bakuchiol reduces wrinkles, bags, and dark circles while lifting eyelids.",
      id: 220,
      sendingClub: 220,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/53193TARCH_1.jpg?v=1717066642",
    },
  ],
  fr: [
    {
      title: "Curly Method",
      summary:
        "2 étapes pour définir, hydrater, nourrir vos boucles et dire adieu aux frisottis pour de bon.",
      id: 53055,
      sendingClub: 53309,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/curly-method-club-new.jpg",
    },
    {
      title: "The Absolute",
      summary:
        "La crème anti-âge avec une technologie naturelle innovante qui contrôle les processus de vieillissement pour régénérer et rajeunir la peau.",
      id: 53123,
      sendingClub: 53275,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-absolute-club-new.jpg",
    },
    {
      title: "Body Power",
      summary:
        "Synergie de deux produits anticellulite et minceur pour tirer le meilleur parti de vos séances d'exercice.",
      id: 53299,
      sendingClub: 53344,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/body-power-club-new.jpg",
    },
    {
      title: "The Real C 30 days",
      summary:
        "Le bouclier anti-âge le plus puissant avec de la vitamine C 100% pure. Agit sur le relâchement, les imperfections, les rides et la peau terne. Format EcoRecharge",
      id: 152,
      sendingClub: 160,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749_1_2_1.jpg?v=1687427038",
    },

    {
      title: "Boombastic",
      summary:
        "Masque capillaire ultra-nourrissant pour une crinière éblouissante en 30 minutes.",
      id: 53183,
      sendingClub: 53225,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/boombastic-club-new.jpg",
    },
    {
      title: "Wondermask",
      summary:
        "Masque purifiant 5 en 1 pour une peau parfaite en 10 minutes. Détoxifie, équilibre la peau et agit contre les pores ouverts.",
      id: 53102,
      sendingClub: 53226,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/wondermask-club-new.jpg",
    },
    {
      title: "The Cure",
      summary:
        "Sérum ultime contenant 21 huiles uniques 100% naturelles et du Bakuchiol pour réparer et traiter les rides.",
      id: 53252,
      sendingClub: 53252,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-cure-club-new.jpg",
    },
    {
      title: "The Extraordinary Oil",
      summary:
        "Huile corporelle 3 en 1 à base d'huiles d'amande, de rose musquée et d'argan qui laisse votre peau hydratée, radieuse et parfaite.",
      id: 52074,
      sendingClub: 53227,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/extraordinary-oil-club-new.jpg",
    },
    {
      title: "Savior",
      summary:
        "Traitement capillaire innovant qui rétablit et répare la fibre capillaire, restaurant ainsi la santé et la force de vos cheveux.",
      id: 53239,
      sendingClub: 53274,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/savior-club-new.jpg",
    },

    {
      title: "All in One",
      summary:
        "La palette parfaite pour mettre en valeur, ombrer et définir le visage et les yeux.",
      id: 53228,
      sendingClub: 53316,
      options: [
        {
          id: 53229,
          sendingClub: 53310,
          text: "Light",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
        },
        {
          id: 53230,
          sendingClub: 53311,
          text: "Medium",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
        },
        {
          id: 53231,
          sendingClub: 53312,
          text: "Tan",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
        },
      ],
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/AllInOne-53228 2.jpg",
    },
    {
      title: "The glow",
      summary:
        "Crème corporelle nourrissante aux particules minérales scintillantes pour une peau ferme, lisse, souple et élastique.",
      id: 53176,
      sendingClub: 53278,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-glow-club-new.jpg",
    },
    {
      title: "Extra defined curls",
      summary:
        "3 étapes pour définir, nourrir et maintenir les cheveux bouclés sans les endommager ni les alourdir.",
      id: 53110,
      sendingClub: 53348,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/rizos-definicion-extra-club-ne.jpg",
    },
    {
      title: "The Lift",
      summary:
        "Sérum pour les yeux qui raffermit instantanément la peau, réduit les rides et combat la fatigue avec des résultats cliniquement prouvés durant plus de 8 heures.",
      id: 276,
      sendingClub: 276,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/222TheLift-1.jpg?v=1721640569",
    },
    {
      title: "The Architect",
      summary:
        "Soin des yeux à l'Acide Hyaluronique et au Bakuchiol qui réduit les rides, les poches et les cernes tout en liftant les paupières.",
      id: 220,
      sendingClub: 220,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/53193TARCH_1.jpg?v=1717066642",
    },
  ],
  it: [
    {
      title: "Metodo Curly",
      summary:
        "2 passaggi per definire, idratare, nutrire i ricci e dire addio al crespo per sempre.",
      id: 53055,
      sendingClub: 53309,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/curly-method-club-new.jpg",
    },
    {
      title: "The Absolute",
      summary:
        "La crema anti-età con un'innovativa tecnologia naturale che controlla i processi di invecchiamento per rigenerare e ringiovanire la pelle.",
      id: 53123,
      sendingClub: 53275,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-absolute-club-new.jpg",
    },
    {
      title: "Body Power",
      summary:
        "Sinergia di due prodotti anticellulite e snellenti per ottenere il massimo dalle tue sessioni di esercizio",
      id: 53299,
      sendingClub: 53344,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/body-power-club-new.jpg",
    },
    {
      title: "The Real C 30 days",
      summary:
        "Il più potente scudo anti-età con vitamina C pura al 100%. Agisce su cedimenti, macchie, rughe e pelle spenta.Formato EcoRIcarica",
      id: 152,
      sendingClub: 160,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749_1_2_1.jpg?v=1687427038",
    },

    {
      title: "Boombastic",
      summary:
        "Maschera per capelli ultra-nutriente per una criniera mozzafiato in 30 minuti.",
      id: 53183,
      sendingClub: 53225,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/boombastic-club-new.jpg",
    },
    {
      title: "Wondermask",
      summary:
        "Maschera purificante 5 in 1 per una pelle perfetta in 10 minuti. Disintossica, riequilibra la pelle e agisce contro i pori aperti.",
      id: 53102,
      sendingClub: 53226,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/wondermask-club-new.jpg",
    },
    {
      title: "The Cure",
      summary:
        "Siero definitivo con 21 unici oli naturali al 100% e Bakuchiol per riparare e trattare le rughe.",
      id: 53252,
      sendingClub: 53252,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-cure-club-new.jpg",
    },
    {
      title: "Olio Straordinario",
      summary:
        "Olio per il corpo 3 in 1 con olio di mandorle, rosa mosqueta e argan che lascia la pelle idratata, luminosa e perfetta.",
      id: 52074,
      sendingClub: 53227,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/extraordinary-oil-club-new.jpg",
    },
    {
      title: "Savior",
      summary:
        "Trattamento innovativo per capelli che ripristina e ripara la fibra capillare, restituendo salute e forza ai capelli.",
      id: 53239,
      sendingClub: 53274,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/savior-club-new.jpg",
    },

    {
      title: "All in One",
      summary:
        "La palette perfetta per evidenziare, ombreggiare e definire il viso e gli occhi.",
      id: 53228,
      sendingClub: 53316,
      options: [
        {
          id: 53229,
          sendingClub: 53310,
          text: "Light",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
        },
        {
          id: 53230,
          sendingClub: 53311,
          text: "Medium",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
        },
        {
          id: 53231,
          sendingClub: 53312,
          text: "Tan",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
        },
      ],
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/AllInOne-53228 2.jpg",
    },
    {
      title: "The glow",
      summary:
        "Crema corpo nutriente con particelle minerali brillanti per una pelle rassodata, liscia, morbida ed elastica.",
      id: 53176,
      sendingClub: 53278,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-glow-club-new.jpg",
    },
    {
      title: "Ricci Extra Definiti",
      summary:
        "3 fasi per definire, nutrire e fissare i capelli ricci senza danneggiare o appesantire il riccio.",
      id: 53110,
      sendingClub: 53348,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/rizos-definicion-extra-club-ne.jpg",
    },
    {
      title: "The Lift",
      summary:
        "Siero occhi che rassoda istantaneamente la pelle, riduce le rughe e combatte la stanchezza con risultati clinicamente provati che durano più di 8 ore.",
      id: 276,
      sendingClub: 276,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/222TheLift-1.jpg?v=1721640569",
    },
    {
      title: "The Architect",
      summary:
        "Trattamento occhi con Acido Ialuronico e Bakuchiol che riduce rughe, borse e occhiaie sollevando le palpebre.",
      id: 220,
      sendingClub: 220,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/53193TARCH_1.jpg?v=1717066642",
    },
  ],
  de: [
    {
      title: "Curly Method",
      summary:
        "2 Schritte, um Locken zu definieren, zu pflegen und mit Feuchtigkeit zu versorgen und sich für immer von Frizz zu verabschieden.",
      id: 53055,
      sendingClub: 53309,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/curly-method-club-new.jpg",
    },
    {
      title: "The Absolute",
      summary:
        "Die Anti-Aging-Creme mit innovativer, natürlicher Technologie, die den Alterungsprozess kontrolliert, um die Haut zu regenerieren und zu verjüngen.",
      id: 53123,
      sendingClub: 53275,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-absolute-club-new.jpg",
    },
    {
      title: "Body Power",
      summary:
        "Synergie von zwei Anti-Cellulite- und Schlankheitsprodukten, um das Beste aus deinen Trainingseinheiten herauszuholen",
      id: 53299,
      sendingClub: 53344,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/body-power-club-new.jpg",
    },
    {
      title: "The Real C 30 days",
      summary:
        "Der stärkste Anti-Ageing-Schutz mit 100% reinem Vitamin C. Wirkt gegen Erschlaffung, Hautunreinheiten, Falten und fahle Haut. EcoRecharge-Format",
      id: 152,
      sendingClub: 160,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749_1_2_1.jpg?v=1687427038",
    },

    {
      title: "Boombastic",
      summary:
        "Ultra-pflegende Haarmaske für eine wunderschöne Mähne in 30 Minuten.",
      id: 53183,
      sendingClub: 53225,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/boombastic-club-new.jpg",
    },
    {
      title: "Wondermask",
      summary:
        "5-in-1-Reinigungsmaske für perfekte Haut in 10 Minuten. Entgiftet, bringt die Haut wieder ins Gleichgewicht und wirkt gegen offene Poren.",
      id: 53102,
      sendingClub: 53226,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/wondermask-club-new.jpg",
    },
    {
      title: "The Cure",
      summary:
        "Ultimatives Serum mit 21 einzigartigen, 100 Prozent natürlichen Ölen und Bakuchiol zur Reparatur und Behandlung von Falten.",
      id: 53252,
      sendingClub: 53252,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-cure-club-new.jpg",
    },
    {
      title: "The Extraordinary Oil",
      summary:
        "3-in-1-Körperöl mit Mandel-, Hagebutten- und Arganöl, das die Haut mit Feuchtigkeit versorgt und sie strahlend und makellos macht.",
      id: 52074,
      sendingClub: 53227,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/extraordinary-oil-club-new.jpg",
    },
    {
      title: "Savior",
      summary:
        "Innovative Haarbehandlung, die die Haarfaser wiederherstellt und repariert und dem Haar Gesundheit und Kraft verleiht.",
      id: 53239,
      sendingClub: 53274,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/savior-club-new.jpg",
    },

    {
      title: "All in One",
      summary:
        "Die perfekte Palette zum Hervorheben, Schattieren und Definieren von Gesicht und Augen.",
      id: 53228,
      sendingClub: 53316,
      options: [
        {
          id: 53229,
          sendingClub: 53310,
          text: "Light",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
        },
        {
          id: 53230,
          sendingClub: 53311,
          text: "Medium",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
        },
        {
          id: 53231,
          sendingClub: 53312,
          text: "Tan",
          img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
        },
      ],
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/AllInOne-53228 2.jpg",
    },
    {
      title: "The glow",
      summary:
        "Pflegende Körpercreme mit brillanten Mineralpartikeln für eine straffe, glatte, weiche und geschmeidige Haut.",
      id: 53176,
      sendingClub: 53278,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/the-glow-club-new.jpg",
    },
    {
      title: "Extra definierte Locken",
      summary:
        "3 Schritte, um lockiges Haar zu definieren, zu nähren und zu fixieren, ohne es zu beschädigen oder zu beschweren.",
      id: 53110,
      sendingClub: 53348,
      options: null,
      img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/rizos-definicion-extra-club-ne.jpg",
    },
    {
      title: "The Lift",
      summary:
        "Augenserum, das die Haut sofort strafft, Falten reduziert und Müdigkeit bekämpft, mit klinisch nachgewiesenen Ergebnissen, die über 8 Stunden anhalten.",
      id: 276,
      sendingClub: 276,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/222TheLift-1.jpg?v=1721640569",
    },
    {
      title: "The Architect",
      summary:
        "Augenbehandlung mit Hyaluronsäure und Bakuchiol reduziert Falten, Tränensäcke und Augenringe und strafft die Augenlider.",
      id: 220,
      sendingClub: 220,
      options: null,
      img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/53193TARCH_1.jpg?v=1717066642",
    },
  ],
};

export default ClubProductsInfo;
